import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const GoogleAnalyticsTracing = () => {
  console.log("GoogleAnalyticsTracing");
  const id = "KX4FCMZTPG";
  const cookie = useSelector((state) => state.cookie);
  const clearGoogleCookie = () => {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage

    Cookies.remove("_ga", { path: "/", domain: document.domain });
    Cookies.remove("_gid", { path: "/", domain: document.domain });
    Cookies.remove("_gat", { path: "/", domain: document.domain });
    Cookies.remove("AMP_TOKEN", { path: "/", domain: document.domain });

    Cookies.remove(`_gac_${id}`, {
      path: "/",
      domain: document.domain,
    });
    Cookies.remove(`_ga_KX4FCMZTPG`, {
      path: "/",
      domain: document.domain,
    });
    Cookies.remove(`_ga_${id}`, {
      path: "/",
      domain: document.domain,
    });

    if (window.ga) window.ga("remove");
    console.log("TRY TO CLEAR");
  };
  useEffect(() => {
    console.log(cookie);
    if (!cookie.accept) {
      clearGoogleCookie();
      console.log("asedasddd");
      return;
    }
    if (!cookie.options.marketing) {
      clearGoogleCookie();
      return;
    }
    if (cookie.accept && cookie.options.marketing) {
      ReactGA.initialize(`G-${id}`);
      ReactGA.send("pageview");
    }
  }, [cookie]);

  return <></>;
};
export default GoogleAnalyticsTracing;
