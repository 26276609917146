const vehicleOne = {
  make: "TOYOTA",
  model: "PREMIO",
  chassis: "NZT240",
  engine: "1NZ-FE",
  year: 2012,
  plateNumber: "DHK-1001",
};

const vehicleTwo = {
  make: "TOYOTA",
  model: "ALLION",
  chassis: "NZT240",
  engine: "1NZ-FE",
  year: 2015,
  plateNumber: "DHK-1002",
};

const vehicleThree = {
  make: "TOYOTA",
  model: "CAMRY",
  chassis: "1C-23",
  engine: "1NZ-FE",
  year: 2015,
  plateNumber: "DHK-3002",
};

export const vehicles = [vehicleOne, vehicleTwo, vehicleThree];
