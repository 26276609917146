const INITIAL_STATE = {
  accept: false,
  options: {
    marketing: false,
    performance: false,
    analytics: false,
    always: true,
  },
};

const cookieReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "cookie/CLEAR_ORDER":
      return {
        ...INITIAL_STATE,
      };

    case "cookie/ACCEPT":
      return {
        ...state,
        accept: true,
      };

    case "cookie/ACCEPT_ALL":
      return {
        ...state,
        accept: true,
        options: {
          marketing: true,
          performance: true,
          analytics: true,
          always: true,
        },
      };

    case "cookie/UPDATE_COOKIE_ACCEPT":
      return {
        ...state,
        accept: action.payload,
      };

    case "cookie/COOKIE_CANCEL":
      return {
        ...state,
        accept: false,
      };

    case "cookie/UPDATE_MARKETING_COOKIE":
      return {
        ...state,
        options: {
          ...state.options,
          marketing: action.payload,
        },
      };

    case "cookie/UPDATE_ANALYTICS_COOKIE":
      return {
        ...state,
        options: {
          ...state.options,
          analytics: action.payload,
        },
      };

    case "cookie/UPDATE_PERFORMANCE_COOKIE":
      return {
        ...state,
        options: {
          ...state.options,
          performance: action.payload,
        },
      };

    default:
      return state;
  }
};

export default cookieReducer;
