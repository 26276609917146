import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  actionOfAddScreenDeviceType,
  actionOfAddScreenWidth,
} from "redux/screen/screen.action";

export default function ScreenController({ children, ...props }) {
  const dispatch = useDispatch();
  const [deviceType, setDeviceType] = useState("NONE");
  const [deviceWidth, setDeviceWidth] = useState(0);

  const deviceWidthHandler = () => {
    setDeviceWidth(window.innerWidth);
    if (window.innerWidth < 640) {
      setDeviceType("sm");
    } else if (window.innerWidth < 768) {
      setDeviceType("md");
    } else if (window.innerWidth < 1024) {
      setDeviceType("lg");
    } else if (window.innerWidth < 1280) {
      setDeviceType("xl");
    } else {
      setDeviceType("2xl");
    }
  };

  useEffect(() => {
    deviceWidthHandler(); // init device width
    window.addEventListener("resize", deviceWidthHandler);
    return () => window.removeEventListener("resize", deviceWidthHandler);
  }, []);

  useEffect(() => {
    dispatch(actionOfAddScreenDeviceType(deviceType));
  }, [deviceType]);
  useEffect(() => {
    dispatch(actionOfAddScreenWidth(deviceWidth));
  }, [deviceWidth]);

  return <>{children}</>;
}
