export const TRACKING_EVENT = {
  clarity: {
    USER_IDENTITY: "userIdentity",
  },
  mixpanel: {
    SEARCH_EVENT: "Search",
    SEARCH_RESULT_EVENT: "Search Result",
    SINGLE_PRODUCT_VIEW: "View Product",
    SEARCH_AUTO_COMPLETE: "Autocomplete",
    USER_SELECT_VEHICLE: "Vehicle Selected",
    USER_ADDED_VEHICLE: "Vehicle Added",
    USER_SHOP_WITHOUT_VEHICLE: "Shop without vehicle",
    USER_APP_CRASH: "USER_APP_CRASH",
    USER_ROUTE_VISIT: "visited",
    USER_SEARCH_PARAM: "USER_SEARCH_PARAM",
    USER_SHOPPING_CART_RAW: "USER_SHOPPING_CART_RAW",
    USER_ADD_CART: "Add to Cart",
    USER_DEDUCT_TO_CART: "Deduct from Cart",
    USER_ABANDON_CART: "Abandon Cart",
    USER_VIEW_CART: "View Cart",
    USER_GENERATE_QUOTATION: "Generate Quotation",
    USER_PRINT_QUOTATION: "Print Quotation",
    USER_EDIT_QUOTATION: "Edit Quotation",
    USER_ORDER_PLACED: "Order Placed",
  },
};
