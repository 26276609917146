export const SEARCH_PARAM_INITIAL_STATE = {
  params: {
    searchQuery: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleEngineNumber: "",
    vehicleChassisNumber: "",
    vehicleYear: "",
    vehicleLicenseNumber: "",
    size: 12,
    page: 1,
    sort__price: "",
  },
  isLoading: false,
};

const actionOfStartLoading = (user) => ({
  type: "searchParam/START_LOADING",
});
const actionOfEndLoading = (user) => ({
  type: "searchParam/END_LOADING",
});

const actionOfSearchQueryParam = (value) => ({
  type: "searchParam/ADD_SEARCH_QUERY_PARAM",
  payload: value,
});

const actionOfSearchQueryCurrentPageToPageInitialValue = () => ({
  type: "searchParam/UPDATE_CURRENT_PAGE_VALUE_TO_INITIAL_PAGE_VALUE",
});

const actionOfSearchQueryAddAllStateParams = (value) => ({
  type: "searchParam/ADD_ALL_STATE_PARAM",
  payload: value,
});
const actionOfSearchQueryUpdateAllStateParams = (value) => ({
  type: "searchParam/UPDATE_ALL_STATE_PARAM",
  payload: value,
});

const actionOfSearchQueryPageIncrement = () => ({
  type: "searchParam/ADD_SEARCH_QUERY_PAGE_INCREMENT",
});

const actionOfVehicleMakeParam = (value) => ({
  type: "searchParam/ADD_VEHICLE_MAKE_PARAM",
  payload: value,
});

const actionOfVehicleModelParam = (value) => ({
  type: "searchParam/ADD_VEHICLE_MODEL_PARAM",
  payload: value,
});

const actionOfVehicleChassisNumberParam = (value) => ({
  type: "searchParam/ADD_VEHICLE_CHASSIS_NUMBER_PARAM",
  payload: value,
});

const actionOfVehicleEngineNumberParam = (value) => ({
  type: "searchParam/ADD_VEHICLE_ENGINE_NUMBER_PARAM",
  payload: value,
});

const actionOfClearStateSearchParam = (value) => ({
  type: "searchParam/CLEAR_STATE",
  payload: value,
});

export {
  actionOfStartLoading,
  actionOfEndLoading,
  actionOfSearchQueryParam,
  actionOfVehicleMakeParam,
  actionOfVehicleModelParam,
  actionOfVehicleChassisNumberParam,
  actionOfVehicleEngineNumberParam,
  actionOfSearchQueryPageIncrement,
  actionOfSearchQueryUpdateAllStateParams,
  actionOfSearchQueryCurrentPageToPageInitialValue,
  actionOfSearchQueryAddAllStateParams,
  actionOfClearStateSearchParam,
};
