export const addNewVehicle = (currentVehicles, vehicleToAdd) => {
  const existingVehicle = currentVehicles.find(
    (vehicle) => vehicle.plateNumber === vehicleToAdd.plateNumber
  );

  if (existingVehicle) {
    return currentVehicles;
  }

  return [...currentVehicles, vehicleToAdd];
};

export const addItemWithOutVehicle = (items, itemToAdd) => {
  if (!items) {
    return [{ ...itemToAdd, quantity: 1 }];
  }
  const existingItem = items.find(
    (item) => item.partNumber === itemToAdd.partNumber
  );

  if (existingItem) {
    return items.map((item) =>
      item.partNumber === itemToAdd.partNumber
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
  }
  return [...items, { ...itemToAdd, quantity: 1 }];
};

export const decreaseItemWithOutVehicle = (items, itemToDecrease) => {
  const existingItem = items.find(
    (item) => item.partNumber === itemToDecrease.partNumber
  );

  if (existingItem.quantity === 1) {
    return items.filter(
      (item) => item.partNumber !== itemToDecrease.partNumber
    );
  }

  return items.map((item) =>
    item.partNumber === itemToDecrease.partNumber
      ? { ...item, quantity: item.quantity - 1 }
      : item
  );
};

export const addCartItemWithVehicle = (items, itemToAdd) => {
  const itemIndex = [itemToAdd[1]];
  let newItems = [...items];
  let newArray = [];
  let arrayToUpdate = [...items[itemIndex]];

  let existingItem = arrayToUpdate.find(
    (item) => item.partNumber === itemToAdd[0].partNumber
  );

  if (existingItem) {
    newArray = arrayToUpdate.map((item) =>
      item.partNumber === itemToAdd[0].partNumber
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    newItems[itemIndex] = [...newArray];
  } else {
    newItems[itemIndex].push({ ...itemToAdd[0], quantity: 1 });
  }

  return [...newItems];
};

export const decreaseCartItemWithVehicle = (items, itemToDelete) => {
  const itemIndex = [itemToDelete[1]];
  let newItems = [...items];
  let newArray = [];
  let arrayToUpdate = [...items[itemIndex]];

  let existingItem = arrayToUpdate.find(
    (item) => item.partNumber === itemToDelete[0].partNumber
  );

  if (existingItem.quantity === 1) {
    newArray = arrayToUpdate.filter(
      (item) => item.partNumber !== itemToDelete[0].partNumber
    );
  } else {
    newArray = arrayToUpdate.map((item) =>
      item.partNumber === itemToDelete[0].partNumber
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
  }
  newItems[itemIndex] = [...newArray];
  return [...newItems];
};
