import { SEARCH_PARAM_INITIAL_STATE } from "./searchparam.action";
import {
  addSearchQueryParam,
  addVehicleModelParam,
  addVehicleMakeParam,
  addPageIncrement,
  addVehicleChassisNumberParam,
  addVehicleEngineNumberParam,
  addVehicleLicenseNumberParam,
} from "./searchparam.utils";

const searchParamReducer = (state = SEARCH_PARAM_INITIAL_STATE, action) => {
  switch (action.type) {
    case "searchParam/START_LOADING":
      return { ...state, isLoading: true };

    case "searchParam/END_LOADING":
      return { ...state, isLoading: false };

    case "searchParam/ADD_SEARCH_QUERY_PARAM":
      return addSearchQueryParam(state, action.payload);

    case "searchParam/UPDATE_CURRENT_PAGE_VALUE_TO_INITIAL_PAGE_VALUE":
      return {
        ...state,
        params: {
          ...state.params,
          page: SEARCH_PARAM_INITIAL_STATE.params.page,
        },
      };

    case "searchParam/ADD_ALL_STATE_PARAM":
      return {
        ...state,
        params: { ...action.payload },
      };
    case "searchParam/UPDATE_ALL_STATE_PARAM":
      return {
        ...state,
        params: { ...state.params, ...action.payload },
      };

    case "searchParam/ADD_VEHICLE_MAKE_PARAM":
      return addVehicleMakeParam(state, action.payload);

    case "searchParam/ADD_VEHICLE_MODEL_PARAM":
      return addVehicleModelParam(state, action.payload);

    case "searchParam/ADD_SEARCH_QUERY_PAGE_INCREMENT":
      return addPageIncrement(state);

    case "searchParam/ADD_VEHICLE_CHASSIS_NUMBER_PARAM":
      return addVehicleChassisNumberParam(state, action.payload);

    case "searchParam/ADD_VEHICLE_ENGINE_NUMBER_PARAM":
      return addVehicleEngineNumberParam(state, action.payload);

    case "searchParam/ADD_VEHICLE_LICENSE_NUMBER_PARAM":
      return addVehicleLicenseNumberParam(state, action.payload);

    case "searchParam/CLEAR_STATE":
      return { ...SEARCH_PARAM_INITIAL_STATE };
    default:
      return state;
  }
};

export default searchParamReducer;
