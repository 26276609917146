import { fetchUserIdToken } from "../functions/auth";

// test@mail.com
// carxparts@123-Test

const AWS_AMPLIFY_CONFIGURATIONS = {
  aws_project_region: "us-east-1",
  // aws_cognito_identity_pool_id:
  //   "us-east-1:1b205604-1d30-42d4-aaa9-bd20bdcc5ba7",
  // aws_cognito_identity_pool_id:
  //   "us-east-1:1b205604-1d30-42d4-aaa9-bd20bdcc5ba7",
  aws_cognito_region: "us-east-1",
  // aws_user_pools_id: "us-east-1_NUbImY3GT",
  aws_user_pools_id: "us-east-1_ykOEg9EPo",
  // aws_user_pools_web_client_id: "1mtsa3m7m28icodr9dag3ofj26",
  aws_user_pools_web_client_id: "pi28bgaibtm8963egec1arabi",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  API: {
    endpoints: [
      {
        name: "search-service-api",
        endpoint: "https://b2b.api.carxparts.io/ssa",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await fetchUserIdToken()}`,
          };
        },
      },
      {
        name: "requestAccess-service-api",
        endpoint: "https://b2b.api.carxparts.io/rsa",
      },
      {
        name: "product-service-api",
        endpoint: "https://b2b.api.carxparts.io/psa",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await fetchUserIdToken()}`,
          };
        },
      },
      {
        name: "upload-service-api",
        endpoint: "https://b2b.api.carxparts.io/upsa",
      },
      {
        name: "user-service-api",
        endpoint: "https://b2b.api.carxparts.io/usa",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await fetchUserIdToken()}`,
          };
        },
      },
      {
        name: "order-service-api",
        endpoint: "https://b2b.api.carxparts.io/osa",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await fetchUserIdToken()}`,
          };
        },
      },
      {
        name: "payment-service-api",
        endpoint: "https://b2b.api.carxparts.io/pasa",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await fetchUserIdToken()}`,
          };
        },
      },
      {
        name: "wishlist-service-api",
        endpoint: "https://b2b.api.carxparts.io/uwsa",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await fetchUserIdToken()}`,
          };
        },
      },
      {
        name: "marketing-service-api",
        endpoint: "https://b2b.api.carxparts.io/msa",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await fetchUserIdToken()}`,
          };
        },
      },
    ],
  },
};

export default AWS_AMPLIFY_CONFIGURATIONS;
