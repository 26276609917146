export const addVehicle = (vehicle) => ({
  type: "ADD_VEHICLE",
  payload: vehicle,
});
export const addCurrentVehicle = (vehicle) => ({
  type: "vehicle/ADD_CURRENT_VEHICLE",
  payload: vehicle,
});

export const selectVehicle = (number) => ({
  type: "SELECT_VEHICLE",
  payload: number,
});

export const addWithOutVehicle = (item) => ({
  type: "ADD_ITEM_WITHOUT_VEHICLE",
  payload: item,
});

export const decreaseWithOutVehicle = (item) => ({
  type: "DECREASE_WITHOUT_VEHICLE",
  payload: item,
});

export const addItemWithVehicle = (item, index) => ({
  type: "ADD_ITEM_WITH_VEHICLE",
  payload: [item, index],
});

export const decreaseItemWithVehicle = (item, index) => ({
  type: "DECREASE_ITEM_WITH_VEHICLE",
  payload: [item, index],
});

export const shopWithOutVehicle = () => ({
  type: "SHOP_WITHOUT_VEHICLE",
});

export const clearWithoutVehicleItems = () => ({
  type: "CLEAR_WITHOUT_VEHICLE_ITEM",
});

export const clearCurrentVehicleItems = () => ({
  type: "vehicle/CLEAR_CURRENT_VEHICLE_ITEMS",
});
