export const addAdminUserToState = (previousState, newUserInfo) => {
  const newState = {
    ...previousState,
    user: {
      ...newUserInfo,
    },
    isFromAdmin: true,
  };
  return newState;
};
