import React from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import ClarityTracking from "layouts/ClarityTracking/index.layout";
import GoogleAnalyticsTracing from "layouts/GoogleAnalyticsTracing/index.layout";
import SentryErrorTracing from "layouts/SentryErrorTracing/index.layout";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store.redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Amplify from "@aws-amplify/core";
import mixpanel from "mixpanel-browser";
import AWS_AMPLIFY_CONFIGURATIONS from "configuration/aws_amplify.configuration";
import MIXPANEL_CONFIG from "configuration/mixpanel.config";
import { ErrorBoundary } from "@sentry/react";
import ErrorBounderFallbackPage from "pages/errorBoundery.page";
// import { QueryClientProvider } from "components/QueryClientProvider/index.component";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Amplify.configure(AWS_AMPLIFY_CONFIGURATIONS);
mixpanel.init(MIXPANEL_CONFIG.key, {
  ...MIXPANEL_CONFIG.option,
});
// mixpanel.track("Hello-world", {
//   source: "testing",
//   "Opted out of email": true,
// });
// mixpanel.identify()

console.log("PROJECT_VERSION", process.env.REACT_APP_VERSION);
const _v = localStorage.getItem("_v");
if (_v && _v === process.env.REACT_APP_VERSION) {
  console.log("App version is same");
} else {
  // localStorage.clear();
  // sessionStorage.clear();
  localStorage.setItem("_v", process.env.REACT_APP_VERSION);
}

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

/** Removing console.log in production */
if (process.env.NODE_ENV !== "development") {
  console.clear();
  // eslint-disable-next-line no-console
  console.log(
    `%c

░█████╗░░█████╗░██████╗░  ██╗░░██╗  ██████╗░░█████╗░██████╗░████████╗░██████╗
██╔══██╗██╔══██╗██╔══██╗  ╚██╗██╔╝  ██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
██║░░╚═╝███████║██████╔╝  ░╚███╔╝░  ██████╔╝███████║██████╔╝░░░██║░░░╚█████╗░
██║░░██╗██╔══██║██╔══██╗  ░██╔██╗░  ██╔═══╝░██╔══██║██╔══██╗░░░██║░░░░╚═══██╗
╚█████╔╝██║░░██║██║░░██║  ██╔╝╚██╗  ██║░░░░░██║░░██║██║░░██║░░░██║░░░██████╔╝
░╚════╝░╚═╝░░╚═╝╚═╝░░╚═╝  ╚═╝░░╚═╝  ╚═╝░░░░░╚═╝░░╚═╝╚═╝░░╚═╝░░░╚═╝░░░╚═════╝░
`,
    "font-family:Inter;color:#1A71B0;font-size:12px;"
  );
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.table = () => {};
  console.disableYellowBox = true;
}

//// TODO: Legacy support React v17.0.2
// ReactDOM.render(
//   <>
//     <Provider store={store}>
//       <SentryErrorTracing />
//       <GoogleAnalyticsTracing />
//       <ClarityTracking />
//       <BrowserRouter>
//         <PersistGate loading={null} persistor={persistor}>
//           <ThemeProvider theme={theme}>
//             <ErrorBoundary fallback={ErrorBounderFallbackPage}>
//               <App />
//             </ErrorBoundary>
//           </ThemeProvider>
//         </PersistGate>
//       </BrowserRouter>
//       {/*  */}
//     </Provider>
//   </>,
//   document.getElementById("root")
// );

// const client = new QueryClient();

//// TODO: React v18.2.0
function AppRoot() {
  return (
    <Provider store={store}>
      <SentryErrorTracing />
      <GoogleAnalyticsTracing />
      <ClarityTracking />
      {/* <QueryClientProvider client={client}> */}
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ErrorBoundary fallback={ErrorBounderFallbackPage}>
              <App />
            </ErrorBoundary>
          </ThemeProvider>
        </PersistGate>
      </BrowserRouter>
      {/* </QueryClientProvider> */}
    </Provider>
  );
}
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppRoot />);
