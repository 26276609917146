const INITIAL_STATE = {
  personalInfo: {},
};

const userCompleteSignupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "userCompleteSignup/APPEND_PERSONAL_INFO":
      const personalInfo = {
        personalInfo: { ...state.personalInfo, ...action.payload },
      };
      return personalInfo;

    default:
      return state;
  }
};

export default userCompleteSignupReducer;
