export const addInitiateOrder = (cart, orderId, priceSummary) => ({
  type: "order/INSTATE_ORDER",
  payload: {
    orderId,
    cart,
    priceSummary,
  },
});

export const addOrderFormStateFileUploadValueAction = (fileUploadKey) => ({
  type: "order/ORDER_FORM_STATE_FILE_UPLOAD_VALUE",
  payload: fileUploadKey,
});

export const addOrderFormStateOrderAddressAction = (address) => ({
  type: "order/ORDER_FORM_STATE_ORDER_ADDRESS",
  payload: address,
});

export const removeOrderFormStateFileUploadAction = (address) => ({
  type: "order/ORDER_FORM_STATE_FILE_UPLOAD_REMOVE",
  payload: address,
});

export const addOrderFormStateOrderPaymentInfoAction = (address) => ({
  type: "order/ORDER_FORM_STATE_ORDER_PAYMENT_INFO",
  payload: address,
});

export const addOrderFormStateUserSIgnedInfoAction = (userSignedInfo) => ({
  type: "order/ORDER_FORM_STATE_USER_SIGNED_INFO",
  payload: userSignedInfo,
});

export const clearOrderState = () => ({
  type: "order/CLEAR_ORDER",
});
