const userStartLoading = (user) => ({
  type: "user/START_LOADING",
});
const userEndLoading = (user) => ({
  type: "user/END_LOADING",
});

const addNewUser = (user) => ({
  type: "user/ADD_USER",
  payload: user,
});

const removeUser = () => ({
  type: "user/REMOVE_USER",
});

export { userStartLoading, userEndLoading, addNewUser, removeUser };
