import { isEmptyObject } from "functions/utils";
import { CountTotalCartItemCarUnderProductQuantity } from "functions/priceCalculator";
import {
  addProductItem,
  addVehicleIntoProductItem,
  removeProductItem,
  removeVehicleIntoProductItem,
  // clearItems,
} from "./cart.action";
import { CART_INITIAL_STATE } from "./cart.reducer";

export const defaultCarObject = { NO_CAR: {} };

export const addToCartActionEvent =
  (productInfo, currentCarInformation) => (dispatch) => {
    dispatch(addProductItem(productInfo));
    dispatch(addVehicleIntoProductItem(currentCarInformation, productInfo));
  };

export const removeToCartActionEvent =
  (productInfo, currentCarInformation) => (dispatch) => {
    dispatch(removeProductItem(productInfo));
    dispatch(removeVehicleIntoProductItem(currentCarInformation, productInfo));
  };

// export const clearCartActionEvent = () => (dispatch) => {
//   dispatch(clearItems());
// };

/**
 * generate vehicle key from the vehicle object
 *
 * @param {object} carInfo  vehicle information
 * @returns  {string} json stringify vehicle information
 */
export const getCarObjectKey = (carInfo = {}) => {
  let car = carInfo;
  if (isEmptyObject(car)) {
    car = { ...defaultCarObject };
  }
  return JSON.stringify(car);
};

export const isCarHaveThisProduct = (
  carUnderProductState = {},
  carInfo = {},
  partNumber = ""
) => {
  const car = carInfo;
  const getCarKey = getCarObjectKey(car);

  if (!carUnderProductState.hasOwnProperty(getCarKey)) {
    return false;
  }

  const carElementProduct = carUnderProductState[getCarKey]["product"] || {};
  if (!carElementProduct.hasOwnProperty(partNumber)) {
    return false;
  }

  return true;
};

export const decreaseItemsFromCart = (cartItems, itemToDecrease) => {
  const existingCartItem = cartItems.find(
    (cartItem) => itemToDecrease.partNumber === cartItem.partNumber
  );

  if (existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem) => cartItem.partNumber !== itemToDecrease.partNumber
    );
  }
  return cartItems.map((cartItem) =>
    cartItem.partNumber === itemToDecrease.partNumber
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

/**
 *
 * Store the product cart information into `productNumberCart` object
 *
 * @param {object} previousState
 * @param {object} newPayload
 * @returns
 */
export const addProductToCart = (previousState, newPayload) => {
  return previousState;
  // const productID = newPayload.partNumber;
  // if (previousState.productNumberCart.hasOwnProperty(productID)) {
  //   console.log("product already in cart");
  //   newPayload.quantity +=
  //     previousState.productNumberCart[productID]["quantity"];
  // }
  // const currentProductCart = {
  //   [newPayload.partNumber]: {
  //     ...newPayload,
  //   },
  // };

  // let currentCartItems = {
  //   ...previousState,
  //   productNumberCart: {
  //     ...previousState.productNumberCart,
  //     ...currentProductCart,
  //   },
  // };
  // const totalCount = countTotalCartItem(currentCartItems);
  // currentCartItems = {
  //   ...currentCartItems,
  //   total_cart_item_count: totalCount,
  // };
  // return currentCartItems;
};

export const removeProductToCart = (previousState, newPayload) => {
  return previousState;
  // const productItemCart = { ...previousState.productNumberCart };
  // if (productItemCart.hasOwnProperty(newPayload.partNumber)) {
  //   delete productItemCart[newPayload.partNumber];
  // }
  // let currentCartItems = {
  //   ...previousState,
  //   productNumberCart: {
  //     ...productItemCart,
  //   },
  // };
  // const totalCount = countTotalCartItem(currentCartItems);
  // currentCartItems = {
  //   ...currentCartItems,
  //   total_cart_item_count: totalCount,
  // };
  // return currentCartItems;
};

export const addCarIntoProductToCart = (previousState, newPayload) => {
  let car = newPayload.carInfo;
  const carID = getCarObjectKey(car);

  const carObjectWithProduct = {
    [carID]: {
      vehicle: { ...car },
      product: {
        ...(previousState.car_under_product[carID]?.product || {}),
        ...{
          [newPayload.product.partNumber]: newPayload.product,
        },
        // ...(previousState.car_under_product[carID].product || []),
        // action.payload.product,
      },
    },
  };
  const car_under_product_variable = {
    ...previousState.car_under_product,
    ...carObjectWithProduct,
  };

  const total_quantity = CountTotalCartItemCarUnderProductQuantity(
    car_under_product_variable
  );
  console.table(previousState.car_under_product);
  console.table(carObjectWithProduct);

  return {
    ...previousState,
    car_under_product: {
      ...car_under_product_variable,
    },
    total_cart_item_count: total_quantity,
  };
};

export const removeCarIntoProductToCart = (previousState, newPayload) => {
  let car = newPayload.carInfo;
  const carID = getCarObjectKey(car);

  const newStateCarUnderProduct = { ...previousState.car_under_product };
  if (newStateCarUnderProduct.hasOwnProperty(carID)) {
    if (
      newStateCarUnderProduct[carID].product.hasOwnProperty(
        newPayload.product.partNumber
      )
    ) {
      delete newStateCarUnderProduct[carID].product[
        newPayload.product.partNumber
      ];
    }
    if (isEmptyObject(newStateCarUnderProduct[carID].product)) {
      delete newStateCarUnderProduct[carID];
    }
  }

  const total_quantity = CountTotalCartItemCarUnderProductQuantity(
    newStateCarUnderProduct
  );
  console.log(
    "cart/ADD_CAR_INTO_PRODUCT_ITEM --- newPayload",
    newPayload,
    carID,
    newStateCarUnderProduct
  );
  return {
    ...previousState,
    car_under_product: { ...newStateCarUnderProduct },
    total_cart_item_count: total_quantity,
  };
};

/**
 *
 * This function responsible for controlling the increment and decrement of the quantity of the product.
 * THis function not used any action in the reducer.
 *
 *
 * @param typeEvent string            "ADD_NEW" or "INCREMENT_QUANTITY" or "DECREMENT_QUANTITY"
 * @param productInfoSource object    original product information
 * @param componentReturnData         information of the component product quantity
 *
 */
export const addNewItemToCart = (
  typeEvent = "ADD_NEW",
  productInfoSource,
  componentReturnData
) => {
  const cartProduct = { ...productInfoSource };
  let productInfo = {
    ...cartProduct,
    quantity: componentReturnData.productQuantity,
  };

  if (typeEvent === "INCREMENT_QUANTITY") {
    productInfo = {
      ...productInfo,
      quantity: productInfo.quantity + 1,
    };
  } else if (typeEvent === "DECREMENT_QUANTITY") {
    const decrementQuantity =
      productInfo.quantity > 0 ? productInfo.quantity - 1 : 0;
    productInfo = {
      ...productInfo,
      quantity: decrementQuantity,
    };
  } else if (typeEvent === "UPDATE_QUANTITY") {
    if (productInfo.quantity >= 1) {
      productInfo = {
        ...productInfo,
        quantity: productInfo.quantity,
      };
    }
  }
  return productInfo;
};

export const addProductsFromOrderDetails = (
  productInfoSource,
  componentReturnData
) => {
  const cartProduct = JSON.parse(JSON.stringify(CART_INITIAL_STATE));
  console.log(cartProduct, componentReturnData);
  cartProduct.car_under_product = componentReturnData.products;
  cartProduct.total_cart_item_count = componentReturnData.totalProductCount;
  return cartProduct;
};
