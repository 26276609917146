import {
  addUserGarageToState,
  removeUserGarageToState,
  replaceUserGarageToState,
} from "./userGarage.utils";

const INITIAL_STATE = {
  garage: {},
  isLoading: false,
};

const garageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "userGarage/START_LOADING":
      return { ...state, isLoading: true };

    case "userGarage/END_LOADING":
      return { ...state, isLoading: false };

    case "userGarage/ADD_USER_GARAGE":
      return addUserGarageToState(state, action.payload);

    case "userGarage/REPLACE_USER_GARAGE":
      return replaceUserGarageToState(state, action.payload);

    case "userGarage/REMOVE_USER_GARAGE":
      return removeUserGarageToState(state, action.payload);

    case "userGarage/CLEAR_ALL_GARAGE":
      return JSON.parse(JSON.stringify(INITIAL_STATE));

    default:
      return state;
  }
};

export default garageReducer;
