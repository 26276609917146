import React from "react";
import loadable from "@loadable/component";
import AuthFeature from "features/components/auth-feature.component";
import LoadingScreen from "components/LoadingScreen/index.component";

const AdminLoginLayout = loadable(
  () => import("layouts/AdminLogin/index.layout"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

function AdminLoginPage() {
  return (
    <>
      <AuthFeature>
        <AdminLoginLayout />
      </AuthFeature>
    </>
  );
}

export default AdminLoginPage;
