import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
// import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const SentryErrorTracing = () => {
  console.log("SentryErrorTracing");
  // const cookie = useSelector((state) => state.cookie);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: "https://638d2c1f09c443dc876544a9ac0bdd05@o1393827.ingest.sentry.io/6715531",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
  }, []);

  return <></>;
};
export default SentryErrorTracing;
