const ALL_FEATURES = {
  AUTH: true, // For Authentication features
  SEARCH: true, // For Search features
  SHOPPING_CART: true, // For Shopping Cart features
  QUOTATION: true, // For Quotation features
  WISHLIST: false, // For Wishlist features
  IMAGE_ZOOM: true, // For Image Zoom features
};

export default ALL_FEATURES;
