const actionOfStartLoading = () => ({
  type: "productSearch/START_LOADING",
});
const actionOfEndLoading = () => ({
  type: "productSearch/END_LOADING",
});
const actionOfProductSearchAddedFiltersOptionsEndLoading = () => ({
  type: "productSearch/ADD_NEW_PRODUCT_SEARCH_FILTERS_OPTION_END_LOADING",
});

const actionOfProductSearchAddedFiltersOptionsStartLoading = () => ({
  type: "productSearch/ADD_NEW_PRODUCT_SEARCH_FILTERS_OPTION_START_LOADING",
});

const actionOfProductSearchAddedFiltersOptions = (value) => ({
  type: "productSearch/ADD_NEW_PRODUCT_SEARCH_FILTERS_OPTION",
  payload: { results: value },
});

const actionOfProductSearchAddedData = (value) => ({
  type: "productSearch/ADD_NEW_PRODUCT_SEARCH_DATA",
  payload: { results: value },
});

const actionOfProductSearchAddMoreData = (value) => ({
  type: "productSearch/ADD_MORE_PRODUCT_SEARCH_DATA",
  payload: { results: value },
});
const actionOfProductSearchAddError = (value) => ({
  type: "productSearch/ADD_ERROR",
  payload: { error: value },
});

const actionOfProductSearchRemoveError = () => ({
  type: "productSearch/REMOVE_ERROR",
});

const actionOfProductSearchClearToInitialState = () => ({
  type: "productSearch/CLEAR_TO_INITIAL_STATE",
});

// const actionOfVehicleModelParam = (value) => ({
//   type: "searchParam/ADD_VEHICLE_MODEL_PARAM",
//   payload: value,
// });

// const actionOfVehicleChassisNumberParam = (value) => ({
//   type: "searchParam/ADD_VEHICLE_CHASSIS_NUMBER_PARAM",
//   payload: value,
// });

// const actionOfVehicleEngineNumberParam = (value) => ({
//   type: "searchParam/ADD_VEHICLE_ENGINE_NUMBER_PARAM",
//   payload: value,
// });

export {
  actionOfStartLoading,
  actionOfEndLoading,
  actionOfProductSearchAddedData,
  actionOfProductSearchAddError,
  actionOfProductSearchRemoveError,
  actionOfProductSearchClearToInitialState,
  actionOfProductSearchAddMoreData,
  actionOfProductSearchAddedFiltersOptions,
  actionOfProductSearchAddedFiltersOptionsEndLoading,
  actionOfProductSearchAddedFiltersOptionsStartLoading,
};
