import { vehicles } from "../../data/vehicle.data";
import {
  addNewVehicle,
  addItemWithOutVehicle,
  addCartItemWithVehicle,
  decreaseCartItemWithVehicle,
  decreaseItemWithOutVehicle,
} from "./vehicle.utils";

const INITIAL_STATE = {
  vehicles: vehicles,
  currentVehicleIndex: 0,
  carts: [[], [], []],
  cartWithOutVehicle: [],
  isVehicleSelected: false,
  currentVehicleInformation: {},
};

const vehicleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "vehicle/ADD_CURRENT_VEHICLE":
      return {
        ...state,
        currentVehicleInformation: action.payload,
      };
    case "ADD_VEHICLE":
      return {
        ...state,
        vehicles: addNewVehicle(state.vehicles, action.payload),
        carts: state.carts.push([]),
      };

    case "SELECT_VEHICLE":
      return {
        ...state,
        isVehicleSelected: true,
        currentVehicleIndex: action.payload,
      };

    case "ADD_ITEM_WITH_VEHICLE":
      return {
        ...state,
        carts: addCartItemWithVehicle(state.carts, action.payload),
      };

    case "ADD_ITEM_WITHOUT_VEHICLE":
      return {
        ...state,
        cartWithOutVehicle: addItemWithOutVehicle(
          state.cartWithOutVehicle,
          action.payload
        ),
      };

    case "DECREASE_WITHOUT_VEHICLE":
      return {
        ...state,
        cartWithOutVehicle: decreaseItemWithOutVehicle(
          state.cartWithOutVehicle,
          action.payload
        ),
      };

    case "SHOP_WITHOUT_VEHICLE":
      return {
        ...state,
        isVehicleSelected: false,
      };

    case "CLEAR_WITHOUT_VEHICLE_ITEM":
      return {
        ...state,
        cartWithOutVehicle: [],
      };

    case "DECREASE_ITEM_WITH_VEHICLE":
      return {
        ...state,
        carts: decreaseCartItemWithVehicle(state.carts, action.payload),
      };

    case "vehicle/CLEAR_CURRENT_VEHICLE_ITEMS":
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default vehicleReducer;
