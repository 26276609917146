import { cloneDeep, uniqBy } from "lodash";

const MAX_RECENTLY_VIEWED_PRODUCT = 12 + 1; // TODO: +1 for current product

export const addRecentlyViewedProduct = (state, product = {}) => {
  const newState = cloneDeep(state);
  if (product.partNumber) {
    const productInfo = {
      partNumber: product.partNumber,
    };
    newState.products.unshift(productInfo);
  }

  const isUnique = uniqBy(newState.products, "partNumber");
  newState.products = [...isUnique];

  if (newState.products.length >= MAX_RECENTLY_VIEWED_PRODUCT) {
    newState.products = newState.products.slice(0, MAX_RECENTLY_VIEWED_PRODUCT);
  }
  return { ...newState };
};
