export const addProductItem = (product) => ({
  type: "cart/ADD_PRODUCT_ITEM",
  payload: product,
});

export const addVehicleIntoProductItem = (carInfo, product) => ({
  type: "cart/ADD_CAR_INTO_PRODUCT_ITEM",
  payload: { carInfo, product },
});

export const removeProductItem = (product) => ({
  type: "cart/REMOVE_PRODUCT_ITEM",
  payload: product,
});

export const removeVehicleIntoProductItem = (carInfo, product) => ({
  type: "cart/REMOVE_CAR_INTO_PRODUCT_ITEM",
  payload: { carInfo, product },
});

export const clearItems = () => ({
  type: "cart/CLEAR_ITEMS",
});

export const addProductsFromOrderDetails = (products, totalProductCount) => ({
  type: "cart/ADD_PRODUCT_FROM_ORDER_DETAILS",
  payload: { products, totalProductCount },
});
