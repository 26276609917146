import loadable from "@loadable/component";
import ScreenController from "layouts/ScreenController/index.layout";
import LoadingScreen from "components/LoadingScreen/index.component";
import { Routes, Route } from "react-router-dom";
import { ROUTER_PATH } from "functions/router";
import { useRouterInfo } from "hooks/useRouterInfo";
import { useEffect } from "react";
import useMixpanel from "hooks/useMixpanel";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AdminLoginPage from "pages/adminLogin.page";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import GlobalLoadingIndicator from "components/GlobalLoadingIndicator/index.component";

const GlobalModalFactory = loadable(() => import("layouts/GlobalModal"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const CookiesLayout = loadable(() => import("layouts/CookiesLayout"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const SearchFeature = loadable(
  () => import("features/components/search-feature.component"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

const ShoppingCartFeature = loadable(
  () => import("features/components/shoppingCart-feature.component"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

const WishlistFeature = loadable(
  () => import("features/components/wishlist-feature.component"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

const LandingPage = loadable(() => import("pages/landing.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const NotFound = loadable(() => import("pages/404.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const AboutUs = loadable(() => import("pages/aboutUs.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const Product = loadable(() => import("pages/product.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const QuotationFeature = loadable(
  () => import("features/components/quotation-feature.component"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

const OrderConfirmation = loadable(
  () => import("pages/orderConfirmation.page"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

const PreOrderPurchase = loadable(() => import("pages/preOrderPurchase.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const UserSingleOrder = loadable(() => import("pages/userSingleOrder.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const LoginPage = loadable(() => import("pages/login.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const CompleteSignUp = loadable(() => import("pages/completeSignup.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const LogoutPage = loadable(() => import("pages/logout.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const CustomerInvoice = loadable(() => import("pages/customerInvoice.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const OfficeInvoice = loadable(() => import("pages/officeInvoice.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const DeliveryChallan = loadable(() => import("pages/deliveryChallan.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const QuotationPDF = loadable(() => import("pages/quotationPDF.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const AccountDetails = loadable(() => import("pages/account.page"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const Header = loadable(() => import("layouts/Header/index.layout"), {
  fallback: (
    <div className="h-min100">
      <LoadingScreen />
    </div>
  ),
});

const Footer = loadable(
  () => import("layouts/LandingPage/Footer/index.layout"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

const WishlistModal = loadable(
  () => import("layouts/WishlistModal/index.layout"),
  {
    fallback: (
      <div className="h-min100">
        <LoadingScreen />
      </div>
    ),
  }
);

const client = new QueryClient({
  refetchOnWindowFocus: false,
  staleTime: 1000 * 30,
});

function App(props) {
  const route = useRouterInfo();
  const mixpanelHook = useMixpanel();

  useEffect(() => {
    mixpanelHook.routeTrack(route);
  }, [route]);

  return (
    <ScreenController>
      <QueryClientProvider client={client}>
        <div className="relative">
          {/* <Routes>
            <Route path={`/new-home`} element={<NewHomePage />} />
          </Routes> */}
          {/* {isFetching || isMutating ? <GlobalLoadingIndicator /> : <></>} */}
          <Header />
          <div className="h-min100">
            <Routes>
              <Route path={ROUTER_PATH.HOME} element={<LandingPage />} />
              <Route
                exact
                path={ROUTER_PATH.SEARCH}
                element={<SearchFeature />}
              />
              <Route
                path={ROUTER_PATH.CART}
                element={<ShoppingCartFeature />}
              />
              <Route
                path={ROUTER_PATH.ADMIN_LOGIN}
                element={<AdminLoginPage />}
              />
              <Route
                path={ROUTER_PATH.QUOTATION}
                element={<QuotationFeature />}
              />
              <Route
                path={ROUTER_PATH.QUOTATION_PDF}
                element={<QuotationPDF />}
              />
              <Route path={ROUTER_PATH.SINGLE_PRODUCT} element={<Product />} />
              <Route
                path={ROUTER_PATH.PER_ORDER_PURCHASE}
                element={<PreOrderPurchase />}
              />
              <Route
                path={ROUTER_PATH.ORDER_CONFIRMATION}
                element={<OrderConfirmation />}
              />
              <Route
                path={ROUTER_PATH.USER_SINGLE_ORDER}
                element={<UserSingleOrder />}
              />
              <Route path={ROUTER_PATH.ACCOUNT} element={<AccountDetails />} />
              <Route
                path={ROUTER_PATH.CUSTOMER_INVOICE}
                element={<CustomerInvoice />}
              />
              <Route
                path={ROUTER_PATH.OFFICE_INVOICE}
                element={<OfficeInvoice />}
              />
              <Route
                path={ROUTER_PATH.DELIVERY_CHALLAN}
                element={<DeliveryChallan />}
              />
              <Route path={ROUTER_PATH.LOGOUT} element={<LogoutPage />} />
              <Route path={ROUTER_PATH.LOGIN} element={<LoginPage />} />
              <Route
                path={ROUTER_PATH.COMPLETE_SIGNUP}
                element={<CompleteSignUp />}
              />
              <Route path={ROUTER_PATH.ABOUT_US} element={<AboutUs />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <GlobalModalFactory />
          <WishlistFeature>
            <WishlistModal />
          </WishlistFeature>
          <CookiesLayout />
          <Footer />
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ScreenController>
  );
}

export default App;
