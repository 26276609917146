import {
  addProductSearchData,
  addMoreProductSearchData,
} from "./productSearch.utils";

const INITIAL_STATE = {
  results: {},
  filters: {
    isLoading: false,
    result: [],
  },
  isLoading: false,
  error: null,
};

const productSearchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "productSearch/START_LOADING":
      return { ...state, isLoading: true };

    case "productSearch/END_LOADING":
      return { ...state, isLoading: false };

    case "productSearch/ADD_ERROR":
      return { ...state, error: action.payload.error };

    case "productSearch/REMOVE_ERROR":
      return { ...state, error: null };

    case "productSearch/ADD_NEW_PRODUCT_SEARCH_DATA":
      return addProductSearchData(state, action.payload.results);

    case "productSearch/ADD_MORE_PRODUCT_SEARCH_DATA":
      return addMoreProductSearchData(state, action.payload.results);

    case "productSearch/ADD_NEW_PRODUCT_SEARCH_FILTERS_OPTION_START_LOADING":
      return { ...state, filters: { ...state.filters, isLoading: true } };

    case "productSearch/ADD_NEW_PRODUCT_SEARCH_FILTERS_OPTION_END_LOADING":
      return { ...state, filters: { ...state.filters, isLoading: false } };

    case "productSearch/ADD_NEW_PRODUCT_SEARCH_FILTERS_OPTION":
      const result = action.payload.results || [];
      return { ...state, filters: { ...state.filters, result } };

    case "productSearch/CLEAR_TO_INITIAL_STATE":
      return { ...INITIAL_STATE };

    // case "productSearch/ADD_VEHICLE_MAKE_PARAM":
    //   return addVehicleMakeParam(state, action.payload);

    // case "productSearch/ADD_VEHICLE_MODEL_PARAM":
    //   return addVehicleModelParam(state, action.payload);

    // case "productSearch/ADD_VEHICLE_CHASSIS_NUMBER_PARAM":
    //   return addVehicleChassisNumberParam(state, action.payload);

    // case "productSearch/ADD_VEHICLE_ENGINE_NUMBER_PARAM":
    //   return addVehicleEngineNumberParam(state, action.payload);

    default:
      return state;
  }
};

export default productSearchReducer;
