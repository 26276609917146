import { Auth, API } from "aws-amplify";
import { isEmptyObject } from "./utils";
export const signOut = async () => {
  try {
    await Auth.signOut();
    // window.location.href = "/";
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
export const fetchUserInfo = async () => {
  const user = await Auth.currentUserInfo();
  // console.log("user: ", user);
  return user;
};

export const fetchCurrentAuthenticatedUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  // console.log("user: ", user);
  return user;
};

export const fetchUserIdToken = async () => {
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return idToken;
};

export const getUserProfileInfo = async () => {
  const apiResponse = await API.get("user-service-api", "/v1/user/");
  const data = apiResponse.results?.personalInfo || {};
  return data;
};

export const getUserSettings = async () => {
  const apiResponse = await API.get("user-service-api", "/v1/user/");
  const data = apiResponse.results?.userSettings || {};
  return data;
};

export const getUserDeliveryAddress = async () => {
  const apiResponse = await API.get("user-service-api", "/v1/user/");
  const data = apiResponse.results?.shippingAddressInfo?.deliveryAddress || {};
  return data;
};

export const getUserCalculatorInfo = async () => {
  const apiResponse = await API.get("user-service-api", "/v1/user/");
  const data = apiResponse.results?.calculatorInfo || {};
  return data;
};

export const updateName = async (name) => {
  const user = await Auth.currentAuthenticatedUser();
  const result = await Auth.updateUserAttributes(user, {
    name: name,
  });
  return result;
};

export const updateUserInfoApi = async (
  personalInfo = {},
  calculatorInfo = {},
  shippingAddressInfo = {},
  userSettings = {}
) => {
  const data = {
    personalInfo: { ...personalInfo },
    calculatorInfo: { ...calculatorInfo },
    shippingAddress: { ...shippingAddressInfo },
    userSettings: { ...userSettings },
  };

  if (isEmptyObject(data.personalInfo)) delete data.personalInfo;
  if (isEmptyObject(data.calculatorInfo)) delete data.calculatorInfo;
  if (isEmptyObject(data.shippingAddress)) delete data.shippingAddress;
  if (isEmptyObject(data.userSettings)) delete data.userSettings;

  const myInit = {
    headers: {
      "content-type": "application/json",
    },
    body: {
      ...data,
    },
  };
  const request = await API.post("user-service-api", "/v1/user/", myInit);
  console.log(request);
  return request;
};

export const fetchUserAccessToken = async () => {
  const accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();
  return accessToken;
};
