import { addRecentlyViewedProduct } from "./recentlyViewedProduct.utils";

const INITIAL_STATE = {
  products: [],
};

const recentlyViewedProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "recentlyViewedProduct/ADD_PRODUCT":
      return addRecentlyViewedProduct(state, action?.payload || null);

    case "recentlyViewedProduct/REMOVE_ALL":
      return JSON.parse(JSON.stringify(INITIAL_STATE));

    default:
      return state;
  }
};

export default recentlyViewedProductReducer;
