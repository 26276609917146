import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const ClarityTracking = () => {
  console.log("ClarityTracking");
  const cookie = useSelector((state) => state.cookie);

  const clearClarityTrackingCookie = () => {
    // https://learn.microsoft.com/en-us/clarity/cookie-list
    Cookies.remove("_clsk", { path: "/", domain: document.domain });
    Cookies.remove("_clck", { path: "/", domain: document.domain });
    Cookies.remove("MUID", { path: "/", domain: document.domain });
    Cookies.remove("CLID", { path: "/", domain: document.domain });
    Cookies.remove("ANONCHK", { path: "/", domain: document.domain });
    Cookies.remove("MR", { path: "/", domain: document.domain });
    Cookies.remove("SM", { path: "/", domain: document.domain });
    // if (window.clarity) window.clarity("remove");
    console.log("TRY TO CLEAR");
  };

  useEffect(() => {
    if (cookie.accept === false) {
      clearClarityTrackingCookie();
      return;
    }
    if (cookie.options.marketing === false) {
      clearClarityTrackingCookie();
      return;
    }
    const script = document.createElement("script");
    script.innerHTML = `
    (function(c,l,a,r,i,t,y){
             c[a] = c[a] || function () { (c[a].q = c[a].q ||
             []).push(arguments) };
             t=l.createElement(r);
             t.async=1;
             t.src="https://www.clarity.ms/tag/"+i;
             y=l.getElementsByTagName(r)[0];
             y.parentNode.insertBefore(t,y);
         })(window, document, "clarity", "script", "den2b5zq6z");`;

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [cookie]);

  return <></>;
};
export default ClarityTracking;
