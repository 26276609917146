import {
  addItemsToOrderCart,
  orderFormStateFileUpload,
  addOrderAddress,
  addOrderPayment,
  addUserSignedInfo,
  removeFileUploadedKey,
} from "./order.utils";

const INITIAL_STATE = {
  orderId: null,
  orderCart: {},
  orderFormState: {},
  orderPriceSummary: {},
  sessionStart: null,
  sessionEnd: null,
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "order/CLEAR_ORDER":
      return JSON.parse(JSON.stringify(INITIAL_STATE));

    case "order/INSTATE_ORDER":
      return addItemsToOrderCart(state, action.payload);
    case "order/ORDER_FORM_STATE_FILE_UPLOAD_VALUE":
      return orderFormStateFileUpload(state, action.payload);
    case "order/ORDER_FORM_STATE_ORDER_ADDRESS":
      return addOrderAddress(state, action.payload);
    case "order/ORDER_FORM_STATE_ORDER_PAYMENT_INFO":
      return addOrderPayment(state, action.payload);
    case "order/ORDER_FORM_STATE_USER_SIGNED_INFO":
      return addUserSignedInfo(state, action.payload);
    case "order/ORDER_FORM_STATE_FILE_UPLOAD_REMOVE":
      return removeFileUploadedKey(state);
    default:
      return state;
  }
};

export default orderReducer;
