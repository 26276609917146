const INITIAL_STATE = {
  identify: {
    id: "NO_USER_ID",
  },
};

const mixPanelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "mixpanel/ADD_IDENTITY":
      return { ...state, identify: { id: action.payload } };
    case "mixpanel/CLEAR_ALL":
      return JSON.parse(JSON.stringify(INITIAL_STATE));
    default:
      return state;
  }
};

export default mixPanelReducer;
