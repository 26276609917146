const INITIAL_STATE = {
  isGridView: true,
  isOpen: false,
};

const applicationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "application/TOGGLE_GRID_VIEW":
      return { ...state, isGridView: action.payload };

    case "application/TOGGLE_WISHLIST_MODAL":
      return { ...state, isOpen: action.payload };

    default:
      return state;
  }
};

export default applicationReducer;
