import AuthUser from "components/AuthUser/index.component";
import ALL_FEATURES from "features/index";

const AuthFeature = ({ children, ...props }) => {
  if (ALL_FEATURES.AUTH) {
    return <AuthUser>{children}</AuthUser>;
  } else {
    return children;
  }
};

export default AuthFeature;
