import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  fetchCurrentAuthenticatedUser,
  fetchUserInfo,
  signOut,
} from "functions/auth";
import { selectUserValue } from "redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { addNewUser, removeUser } from "redux/user/user.action";
import style from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { Hub } from "aws-amplify";
import { ROUTER_PATH } from "functions/router";
import { isEmptyObject } from "functions/utils";
import { clearItems } from "redux/cart/cart.action";
import { clearOrderState } from "redux/order/order.action";
import { actionOfProductSearchClearToInitialState } from "redux/productSearch/productSearch.action";
import { actionOfClearStateSearchParam } from "redux/searchParam/searchparam.action";
import { clearCurrentVehicleItems } from "redux/vehicle/vehicle.action";
import { addIdentity, clearMixpanelAll } from "redux/mixpanel/mixpanel.action";
import { TRACKING_EVENT } from "data/trackingEvent.data";
import ReactGA from "react-ga4";
import useMixpanel from "hooks/useMixpanel";
import * as Sentry from "@sentry/browser";
// import { ROUTER_PATH } from "functions/router";

const mapStateToProps = createStructuredSelector({
  userItem: selectUserValue,
});

const mapDispatchToProps = (dispatch) => ({
  addNewUser: (user) => dispatch(addNewUser(user)),
  addIdentity: (user) => dispatch(addIdentity(user)),
  removeUser: () => dispatch(removeUser()),
});

// A hoc for the setting the user data in the redux store
export const withCurrentUser = (Component) => {
  const HOC = () => {
    const dispatch = useDispatch();
    const mixPanel = useMixpanel();

    useEffect(() => {
      fetchUserInfo().then((user) => {
        if (user) {
          // console.log("withCurrentUser");
          mixPanel.identify(user?.attributes?.email || "NO_USER_ID");
          mixPanel.setPeople(user.attributes);
          Sentry.setUser({ email: user?.attributes?.email || "NO_USER_ID" });
          Sentry.setContext("user", user.attributes);
          console.log("withCurrentUser");
          dispatch(addIdentity(user?.attributes?.email || "NO_USER_ID"));

          if (window.clarity) {
            window.clarity(
              "set",
              TRACKING_EVENT.clarity.USER_IDENTITY,
              user?.attributes?.email
            );
          }
          ReactGA.event({
            category: "category value",
            action: "action value",
            label: "label value",
            nonInteraction: true,
          });

          dispatch(addNewUser(user));
        } else {
          signOut();
          dispatch(clearMixpanelAll());
          dispatch(removeUser());
        }
      });
    }, []);

    return <Component />;
  };
  return HOC;
};

// const AuthUserHub = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(({ userItem, addNewUser, removeUser, children, ...props }) => {
//   // const navigate = useNavigate();

//   // useEffect(() => {
//   //   Hub.listen("auth", (givenData) => {
//   //     console.log("Listening for all messages: ", givenData);
//   //     const { data, event } = givenData.payload;
//   //     console.log("event: ", event);
//   //     console.log("data: ", data, event);
//   //     // switch (event) {
//   //     //   case "signIn":
//   //     //     console.log("signIn");
//   //     //     fetchUserInfo().then((user) => addNewUser(user));
//   //     //     break;
//   //     //   case "signUp":
//   //     //     console.log("signUp");
//   //     //     fetchUserInfo().then((user) => addNewUser(user));
//   //     //     break;
//   //     //   case "signIn_failure":
//   //     //     console.log("signIn_failure");
//   //     //     removeUser();
//   //     //     break;
//   //     //   case "tokenRefresh":
//   //     //     console.log("tokenRefresh");
//   //     //     fetchUserInfo().then((user) => addNewUser(user));
//   //     //     break;
//   //     //   case "tokenRefresh_failure":
//   //     //     console.log("tokenRefresh_failure");
//   //     //     fetchUserInfo().then((user) => addNewUser(user));
//   //     //     break;
//   //     //   case "configured":
//   //     //     console.log("configured");
//   //     //     fetchUserInfo().then((user) => addNewUser(user));
//   //     //     break;
//   //     //   case "signOut":
//   //     //     removeUser();
//   //     //     break;
//   //     //   default:
//   //     //     break;
//   //     // }
//   //   });
//   //   // if (userItem.user === null) {
//   //   //   fetchUserInfo().then((user) => addNewUser(user));
//   //   // } else {
//   //   //   fetchUserInfo()
//   //   //     .then((user) => {
//   //   //       if (isEmptyObject(user)) {
//   //   //         navigate(ROUTER_PATH.LOGOUT);
//   //   //       }
//   //   //     })
//   //   //     .catch((e) => navigate(ROUTER_PATH.LOGOUT));
//   //   // }
//   //   return () => {
//   //     Hub.remove("auth");
//   //   };
//   // }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   return <></>;
// });

const components = {
  Header() {
    return (
      <div className={style.container__logo}>
        <a href={"/"}>
          <img alt="CarXParts logo" src="/logo-auth-header.png" />
        </a>
      </div>
    );
  },

  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();
      const navigate = useNavigate();
      const requestAccessClick = () => {
        navigate(`/#request-access`);
      };
      return (
        <>
          <div
            style={{
              padding: "0 2rem",
            }}
          >
            <button
              type="button"
              onClick={requestAccessClick}
              style={{
                background: "white",
                color: "#676767",
                border: "1px solid #676767",
              }}
              className="amplify-button amplify-field-group__control amplify-button--primary amplify-button--fullwidth"
            >
              Request Access
            </button>
          </div>
          <div
            className={style.container_login__forgot_pass}
            onClick={toResetPassword}
          >
            <p>Forgot Password?</p>
          </div>
          {/* <div className={style.container_login__sign_up}>
            Don’t have an account?{" "}
            <div className={style.sign_up_text} onClick={toSignUp}>
              {" "}
              Sign up{" "}
            </div>
          </div> */}
        </>
      );
    },
  },

  SignUp: {
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <>
          <div className={style.container_login__sign_up}>
            <p>
              Already have an account?{" "}
              <span className={style.sign_up_text} onClick={toSignIn}>
                {" "}
                Sign in{" "}
              </span>
            </p>
          </div>
        </>
      );
    },
  },
};

const AuthUser = ({
  userItem,
  addIdentity,
  addNewUser,
  removeUser,
  children,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixPanel = useMixpanel();
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", (givenData) => {
      console.log("Listening for all messages: ", givenData);
      const { data, event } = givenData.payload;
      console.log("event: ", event);
      console.log("data: ", data, event);
      switch (event) {
        case "signIn":
          console.log("signIn");
          dispatch(clearItems());
          dispatch(clearOrderState());
          dispatch(actionOfProductSearchClearToInitialState());
          dispatch(actionOfClearStateSearchParam());
          dispatch(clearCurrentVehicleItems());
          // fetchUserInfo().then((user) => addNewUser(user));
          break;
        case "signUp":
          console.log("signUp");
          // fetchUserInfo().then((user) => addNewUser(user));
          break;
        case "signIn_failure":
          console.log("signIn_failure");
          // removeUser();
          break;
        case "tokenRefresh":
          console.log("tokenRefresh");
          // fetchUserInfo().then((user) => addNewUser(user));
          break;
        case "tokenRefresh_failure":
          console.log("tokenRefresh_failure");
          // fetchUserInfo().then((user) => addNewUser(user));
          break;
        case "configured":
          console.log("configured");
          // fetchUserInfo().then((user) => addNewUser(user));
          break;
        case "signOut":
          // removeUser();
          break;
        default:
          break;
      }
    });

    fetchCurrentAuthenticatedUser()
      .then((userData) => {
        console.log("fetchCurrentAuthenticatedUser", userData);
        dispatch(addIdentity(userData?.attributes?.email || "NO_USER_ID"));
        addNewUser(userData);
      })
      .catch(() => console.log("user not found"));

    if (userItem.user !== null) {
      fetchUserInfo()
        .then((userData) => {
          mixPanel.identify(userData?.attributes?.email || "NO_USER_ID");
          mixPanel.setPeople(userData?.attributes);
          Sentry.setUser({
            email: userData?.attributes?.email || "NO_USER_ID",
          });
          Sentry.setContext("user", userData.attributes);
          console.log("asdasd", userData);
          if (isEmptyObject(userData)) {
            navigate(ROUTER_PATH.LOGOUT);
          }
        })
        .catch((e) => navigate(ROUTER_PATH.LOGOUT));
    }

    return unsubscribe;
  }, []);
  return (
    <>
      {/* <AuthUserHub userItem addNewUser removeUser {...props} /> */}
      <Authenticator components={components} variation="modal">
        {children}
      </Authenticator>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthUser);

// // import { withAuthenticator } from "@aws-amplify/ui-react";
// import "@aws-amplify/ui-react/styles.css";
// import { connect } from "react-redux";
// import { useEffect } from "react";
// // import { signOut, fetchUserInfo } from "functions/auth";
// import { fetchUserInfo } from "functions/auth";
// import { selectUserValue } from "redux/user/user.selector";
// import { createStructuredSelector } from "reselect";
// import { Hub } from "aws-amplify";
// import { Authenticator } from "@aws-amplify/ui-react";
// import { addNewUser, removeUser } from "redux/user/user.action";

// const AuthUser = ({ userItem, addNewUser, removeUser, children, ...props }) => {
//   useEffect(() => {
//     Hub.listen("auth", (givenData) => {
//       // console.log("Listening for all messages: ", givenData);
//       const { data, event } = givenData.payload;
//       console.log("event: ", event);
//       console.log("data: ", data);
//       switch (event) {
//         case "signIn":
//           fetchUserInfo().then((user) => addNewUser(user));
//           break;
//         case "signUp":
//           break;
//         case "signOut":
//           removeUser();
//           break;
//         default:
//           break;
//       }
//     });
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   return (
//     <>
//       <Authenticator variation="modal">
//         {({ signOut, user }) => {
//           console.log("user: ", user);
//           return (
//             <>
//               <pre>{JSON.stringify(userItem, null, "\t")}</pre>
//               <h1>Hello {user.username}</h1>
//               <button onClick={signOut}> Sign Out User </button>
//               {children}
//             </>
//           );
//         }}
//       </Authenticator>
//     </>
//   );
// };

// const mapStateToProps = createStructuredSelector({
//   userItem: selectUserValue,
// });

// const mapDispatchToProps = (dispatch) => ({
//   addNewUser: (user) => dispatch(addNewUser(user)),
//   removeUser: () => dispatch(removeUser()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(AuthUser);
