// import useMixpanel from "hooks/useMixpanel";
import React from "react";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ErrorBounderFallbackPage = ({
  error,
  componentStack,
  resetError,
  ...props
}) => {
  const env = process.env.NODE_ENV === "production";
  // const mixPanelHook = useMixpanel();

  // useEffect(() => {
  //   // mixPanelHook.mixpanelAddCrashApplicationTrack({
  //   //   error: error,
  //   //   errorString: error.toString(),
  //   //   componentStack: componentStack,
  //   //   project: "web_b2b",
  //   // });
  //   console.log("errorOccur");
  // }, []);
  return (
    <>
      <NotFoundContainer>
        <div>Oops! An error occur.</div>
        <div>
          <a href="/">Back to Home</a>
        </div>
        {/* <p>{error.toString()}</p>
        <p>{componentStack}</p> */}
        {!env && (
          <>
            <p>{error.toString()}</p>
            <p>{componentStack}</p>
          </>
        )}
      </NotFoundContainer>
    </>
  );
};

export default ErrorBounderFallbackPage;
