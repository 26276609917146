export const ROUTER_PATH = {
  HOME: "/",
  SEARCH: "/search",
  ADMIN_LOGIN: "/admin-login",
  CART_TEST: "/cartTest",
  CART: "/cart",
  GARAGE: "/garage",
  ACCOUNT: "/account/:tabId",
  ACCOUNT_PREFIX: "/account",
  ACCOUNT_TABS: {
    PAYMENT_INFO: "payment-info",
    PERSONAL_INFO: "personal-info",
    SECURITY: "security",
    ORDER_HISTORY: "order-history",
    AIT_VAT: "ait-vat",
    DELIVERY_ADDRESS: "delivery-address",
  },
  QUOTATION: "/quotation",
  QUOTATION_PDF: "/quotation/:quotationId",
  SINGLE_PRODUCT: "/product/:productId",
  SINGLE_PRODUCT_PREFIX: "/product",
  LOGIN: "/login",
  COMPLETE_SIGNUP: "/complete-signup",
  LOGOUT: "/logout",
  PAYMENT_INFO: "/account/payment-info",
  USER_SINGLE_ORDER: "/account/order/:orderId",
  USER_SINGLE_ORDER_PREFIX: "/account/order",
  USER_PERSONAL_INFO: "/account/personal-info",
  USER_SECURITY_INFO: "/account/security",
  USER_ORDER_HISTORY: "/account/order-history",
  USER_AIT_VAT: "/account/ait-vat",
  USER_DELIVERY_ADDRESS: "/account/delivery-address",
  CUSTOMER_INVOICE: "/account/order/:orderId/customerInvoice",
  OFFICE_INVOICE: "/account/order/:orderId/officeInvoice",
  DELIVERY_CHALLAN: "/account/order/:orderId/deliveryChallan",
  ORDER_CONFIRMATION: "/order-confirmation",
  PER_ORDER_PURCHASE: "/per-order-purchase",
  PAGE_NOT_FOUND: "/404",
  ABOUT_US: "/about-us",
};
