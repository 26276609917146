export const CountTotalCartItemQuantity = (fullState) => {
  const fullStateCopy = { ...fullState };
  const { productNumberCart } = fullStateCopy;
  let total_quantity = 0;
  for (const key in productNumberCart) {
    const element = productNumberCart[key];
    total_quantity += element.quantity;
  }
  return total_quantity;
};

export const CountTotalCartItemCarUnderProductQuantity = (
  carUnderProductObject = {}
) => {
  let totalCount = 0;
  for (const vehicleKey in carUnderProductObject) {
    if (Object.hasOwnProperty.call(carUnderProductObject, vehicleKey)) {
      const elementVehicle = carUnderProductObject[vehicleKey];
      if (Object.hasOwnProperty.call(elementVehicle, "product")) {
        const vehicleProcuctElement = elementVehicle.product;
        for (const productKey in vehicleProcuctElement) {
          if (Object.hasOwnProperty.call(vehicleProcuctElement, productKey)) {
            const productElement = vehicleProcuctElement[productKey];
            totalCount += productElement.quantity;
          }
        }
      }
    }
  }
  return totalCount;
};

export const TotalPriceCalculate = (
  singleProductPrice,
  singleProductQuantity,
  AIT = 0.0,
  VAT = 0.0
) => {
  const totalPrice = parseFloat(
    parseFloat(singleProductPrice) * parseFloat(singleProductQuantity)
  );
  const totalAIT = parseFloat(totalPrice * AIT);
  const totalVAT = parseFloat(totalPrice * VAT);
  const totalNet = parseFloat(totalPrice + totalAIT + totalVAT);
  return {
    total_price: totalPrice.toFixed(2),
    total_ait: totalAIT.toFixed(2),
    total_vat: totalVAT.toFixed(2),
    total_net: totalNet.toFixed(2),
  };
};

export const calculateWeightPrice = (totalWeight) => {
  //paperfly.com.bd/charges/

  if (totalWeight <= 0) {
    return (0).toFixed(2);
  }

  const FIRST_KG_PRICE = 130; // NOTE:must be changed value based on business price
  const EXCEPT_FIRST_KG_PRICE = 30; // NOTE:must be changed value based on business price
  const totalWeightInKG = totalWeight / 1000.0;

  let totalPrice = 0;
  if (totalWeightInKG <= 1) {
    totalPrice = FIRST_KG_PRICE * totalWeightInKG;
  } else {
    const exceptFirstKG = totalWeightInKG - 1.0;
    totalPrice = 1.0 * FIRST_KG_PRICE + exceptFirstKG * EXCEPT_FIRST_KG_PRICE;
  }
  return totalPrice.toFixed(2);
};

const discountCalculate = (productElement) => {
  return productElement.price < productElement.original_price;
};

export const totalNetPriceCalculate = (
  totalOriginalNetPrice,
  totalTotalWeightPrice
) => (totalOriginalNetPrice + totalTotalWeightPrice).toFixed(2);

export const OverallVehiclePriceCalculator = (
  carUnderProduct = {},
  AIT = 0.0,
  VAT = 0.0
) => {
  let hasAnyDiscount = false;
  const returnObject = {};
  const carUnderProductObject = Object.assign({}, carUnderProduct);
  for (const vehicleKey in carUnderProductObject) {
    if (Object.hasOwnProperty.call(carUnderProductObject, vehicleKey)) {
      const elementVehicle = { ...carUnderProductObject[vehicleKey] };

      if (Object.hasOwnProperty.call(elementVehicle, "product")) {
        let totalQuantity = 0;
        let totalProductPrice = 0;
        let totalAitPrice = 0;
        let totalVatPrice = 0;
        let totalNetPrice = 0;
        let totalProductWeight = 0;
        let totalOriginalPrice = 0;
        let totalOriginalNetPrice = 0;

        const vehicleProductElement = { ...elementVehicle.product };
        let vehicleProductInitialization = {};

        let hasVehicleUnderProductDiscount = false;

        for (const productKey in vehicleProductElement) {
          if (Object.hasOwnProperty.call(vehicleProductElement, productKey)) {
            let productElement = { ...vehicleProductElement[productKey] };

            if (discountCalculate(productElement)) {
              hasAnyDiscount = true;
              hasVehicleUnderProductDiscount = true;
            }

            // calculate total price with AIT and VAT
            const productPriceCalculate = TotalPriceCalculate(
              productElement.price || 0,
              productElement.quantity,
              AIT,
              VAT
            );

            const productOriginalPriceCalculate = TotalPriceCalculate(
              productElement.original_price || 0,
              productElement.quantity,
              AIT,
              VAT
            );

            productElement = {
              ...productElement,
              ...productPriceCalculate,
              productOriginalPrice: productOriginalPriceCalculate,
            };

            productElement.price = productElement.price.toFixed(2);

            totalQuantity += productElement.quantity; // count the total quantity
            totalProductWeight +=
              parseFloat(productElement.weight * productElement.quantity) || 0; // count the total weight
            totalProductPrice += parseFloat(productElement.total_price || 0); // count the total price
            totalAitPrice += parseFloat(productElement.total_ait || 0); // count the total AIT
            totalVatPrice += parseFloat(productElement.total_vat || 0); // count the total VAT
            totalNetPrice += parseFloat(productElement.total_net || 0); // count the total NET

            totalOriginalPrice += parseFloat(
              productElement.productOriginalPrice.total_price || 0
            );
            totalOriginalNetPrice += parseFloat(
              productElement.productOriginalPrice.total_net || 0
            );

            vehicleProductInitialization[productKey] = {
              ...vehicleProductInitialization,
              ...productElement,
            };
            console.log(productElement, totalProductWeight);
          }
        }
        const totalWeightCost =
          parseFloat(calculateWeightPrice(totalProductWeight) + "") || 0;
        // assigning the data into returnObject with vehicle key
        console.log(totalWeightCost, totalProductWeight);
        returnObject[vehicleKey] = {
          ...elementVehicle,
          product: { ...vehicleProductInitialization },
          total_quantity_count: totalQuantity,
          total_product_weight_count: totalProductWeight,
          total_product_weight_price: totalWeightCost.toFixed(2),
          total_ait_price: totalAitPrice.toFixed(2),
          total_vat_price: totalVatPrice.toFixed(2),
          // total_net_price: totalNetPriceCalculate(
          //   totalNetPrice,
          //   totalWeightCost
          // ),
          total_net_price: totalNetPrice.toFixed(2),
          total_product_price: totalProductPrice.toFixed(2),
          total_original_price: totalOriginalPrice.toFixed(2),
          total_original_net_price: totalOriginalNetPrice.toFixed(2),
          has_vehicle_under_product_discount: hasVehicleUnderProductDiscount,
          has_any_discount: hasAnyDiscount,
        };
      }
    }
  }
  return returnObject;
};
