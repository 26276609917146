import { addAdminUserToState } from "./adminLoginUser.utils";

const INITIAL_STATE = {
  user: null,
  isFromAdmin: false,
};

const adminUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "adminUser/ADD_USER":
      return addAdminUserToState(state, action.payload);

    case "adminUser/REMOVE_USER":
      return JSON.parse(JSON.stringify(INITIAL_STATE));

    default:
      return state;
  }
};

export default adminUserReducer;
