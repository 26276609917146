import { API } from "aws-amplify";
import {
  actionOfEndLoading,
  actionOfStartLoading,
  actionOfProductSearchAddError,
  actionOfProductSearchRemoveError,
  actionOfProductSearchAddedData,
  actionOfProductSearchAddMoreData,
  actionOfProductSearchAddedFiltersOptions,
  actionOfProductSearchAddedFiltersOptionsEndLoading,
  actionOfProductSearchAddedFiltersOptionsStartLoading,
} from "./productSearch.action";

// import { actionOfProductSearchClearToInitialState } from "redux/productSearch/productSearch.action";

function commonInit(dispatch) {
  dispatch(actionOfProductSearchRemoveError());
  dispatch(actionOfStartLoading());
  dispatch(actionOfProductSearchAddedFiltersOptionsStartLoading());
}

function commonEnd(dispatch) {
  dispatch(actionOfEndLoading());
  dispatch(actionOfProductSearchAddedFiltersOptionsEndLoading());
}

const searchProductsAPIFirstRequestDispatch =
  (searchValue, type = "NEW_ADDED") =>
  async (dispatch) => {
    commonInit(dispatch);

    try {
      const myInit = {
        headers: {},
        queryStringParameters: {
          ...searchValue,
        },
      };
      const apiResponse = await API.get(
        "search-service-api",
        "/v1/search/products",
        myInit
      );
      const { search_results } = apiResponse;
      const { data } = search_results;
      const { aggregations } = data;
      // const { have_next_page, total, data } = search_results;
      if (type === "NEW_ADDED") {
        // dispatch(actionOfProductSearchClearToInitialState());
        dispatch(actionOfProductSearchAddedData(search_results));
      } else if (type === "ADD_MORE") {
        dispatch(actionOfProductSearchAddMoreData(search_results));
      }
      dispatch(actionOfProductSearchAddedFiltersOptions(aggregations));
    } catch (error) {
      dispatch(
        actionOfProductSearchAddError({
          message: error.message,
          name: error.name,
        })
      );
    }
    commonEnd(dispatch);
  };

const searchProductsFilterOptionsAPI = (searchValue) => async (dispatch) => {
  dispatch(actionOfProductSearchAddedFiltersOptionsStartLoading());
  try {
    const myInit = {
      headers: {},
      queryStringParameters: {
        ...searchValue,
      },
    };
    const apiResponse = await API.get(
      "search-service-api",
      "/v1/search/products",
      myInit
    );
    const { search_results } = apiResponse;
    const { data } = search_results;
    const { aggregations } = data;
    dispatch(actionOfProductSearchAddedFiltersOptions(aggregations));
  } catch (error) {
    dispatch(
      actionOfProductSearchAddError({
        message: error.message,
        name: error.name,
      })
    );
  }
  dispatch(actionOfProductSearchAddedFiltersOptionsEndLoading());
};

const addProductSearchData = (previousPayload, newPayload) => {
  const newData = {
    ...previousPayload,
  };
  newData.results = { ...newData.results, ...newPayload };
  return newData;
};

const addMoreProductSearchData = (previousPayload, newPayload) => {
  const newData = {
    ...previousPayload,
  };
  console.log("previousPayload", previousPayload);
  console.log("newPayload", newPayload);
  console.log("newData", newData);
  newData.results.data.results = [
    ...newData.results.data.results,
    ...newPayload.data.results,
  ];
  return newData;
};

export {
  searchProductsAPIFirstRequestDispatch,
  addProductSearchData,
  addMoreProductSearchData,
  searchProductsFilterOptionsAPI,
};
