import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage/session";
// import storage from "redux-persist/lib/storage";
import cartReducer from "./cart/cart.reducer";
import storage from "redux-persist-indexeddb-storage";
import vehicleReducer from "./vehicle/vehicle.reducer";
import userReducer from "./user/user.reducer";
import searchParamReducer from "./searchParam/searchparam.reducer";
import productSearchReducer from "./productSearch/productSearch.reducer";
import screenReducer from "./screen/screen.reducer";
import userGarageReducer from "./userGarage/userGarage.reducer";
import orderReducer from "./order/order.reducer";
import cookieReducer from "./cookie/cookie.reducer";
import applicationReducer from "./application/application.reducer";
import mixPanelReducer from "./mixpanel/mixpanel.reducer";
import userCompleteSignupReducer from "./userCompleteSignup/user.reducer";
import recentlyViewedProductReducer from "./recentlyViewedProduct/recentlyViewedProduct.reducer";
import adminUserReducer from "./adminLoginUser/adminLoginUser.reducer";

const persistConfig = {
  key: "root",
  storage: storage("userStateStorage"),
  whitelist: [
    "cart",
    "vehicle",
    "user",
    "userGarage",
    "cookie",
    "application",
    "recentlyViewedProducts",
    "mixpanel",
    "adminUser",
  ],
};

const rootReducer = combineReducers({
  cart: cartReducer,
  vehicle: vehicleReducer,
  user: userReducer,
  searchParam: searchParamReducer,
  productSearch: productSearchReducer,
  screen: screenReducer,
  userGarage: userGarageReducer,
  order: orderReducer,
  cookie: cookieReducer,
  application: applicationReducer,
  userCompleteSignup: userCompleteSignupReducer,
  recentlyViewedProducts: recentlyViewedProductReducer,
  mixpanel: mixPanelReducer,
  adminUser: adminUserReducer,
});

export default persistReducer(persistConfig, rootReducer);
