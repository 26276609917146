const addUserToState = (previousState, newUserInfo) => {
  const newState = {
    ...previousState,
    isLoading: false,
    isUserLoggedIn: true,
    user: newUserInfo,
  };
  return newState;
};

const removeUserToState = (previousState) => {
  const newState = {
    ...previousState,
    isLoading: false,
    isUserLoggedIn: false,
    user: null,
  };
  return newState;
};

export { addUserToState, removeUserToState };
