import { useMemo } from "react";
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
  matchPath,
} from "react-router-dom";
import queryString from "query-string";
import qs from "qs";
// import merge from "deepmerge";

export function useRouterInfo() {
  const params = useParams();
  const searchParams = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryPurser = qs.parse(location.search ?? "", {
    ignoreQueryPrefix: true,
    allowDots: true,
  });

  const isMatchedCurrentPath = (routerPath) => {
    const match = matchPath({ path: routerPath }, location.pathname)?.pattern
      ?.path;
    return match === routerPath;
  };

  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      queryPurser: queryPurser,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      location,
      searchParams,
      navigate,
      isMatchedCurrentPath,
      generateQueryParams: (param) => {
        const newQsString = qs.stringify(
          { ...queryPurser, ...param },
          {
            allowDots: true,
            addQueryPrefix: true,
          }
        );
        return newQsString;
      },
    };
  }, [params, searchParams, location]);
}
