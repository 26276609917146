import DOMPurify from "dompurify";
import { ROUTER_PATH } from "./router";

function isTwoObjectSame(objectOne, objectTwo) {
  return JSON.stringify(objectOne) === JSON.stringify(objectTwo);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function isEmptyObject(obj) {
  if (obj) {
    return JSON.stringify(obj) === "{}";
  } else {
    return true;
  }
  // return Object.keys(obj).length === 0 && obj.constructor === Object;
}
const getNumberOfPages = (total = 0, perPageElement = 6) => {
  if (perPageElement <= 0) {
    return 0;
  }
  if (total === undefined || total <= 0) {
    return 0;
  }

  return Math.ceil(total / perPageElement);
};

const getCurrentPreviewCount = (
  currentPage = 0,
  perPageElement = 6,
  totalProduct = 6
) => {
  let start = (currentPage - 1) * perPageElement + 1;
  let end = currentPage * perPageElement;
  if (totalProduct <= end) {
    end = totalProduct;
  }
  return {
    start,
    end,
  };
};

const getNumberOfPagesInArray = (total = 0, perPageElement = 6) => {
  const numberOfPages = getNumberOfPages(total, perPageElement);
  const pages = [];
  for (let i = 1; i <= numberOfPages; i++) {
    pages.push(i);
  }
  return pages;
};

const getAppVersion = () => {
  const _v = localStorage.getItem("_v") || "0.0.0";
  return _v;
};

const printDocumentWindow = (printElementRef) => {
  let divContents = { ...printElementRef };
  // let notPrintItem = divContents.current.getElementsByClassName("not-print");
  divContents.current.classList.remove("not-print");
  divContents = divContents.current.innerHTML;
  // divContents
  let a = window.open(
    "",
    "Print Quotation",
    `height=${window.screen.height}, width=${window.screen.width}`
  );
  let headTags = document.getElementsByTagName("head");

  let headElement = "<head></head>";
  if (headTags.length > 0) {
    headElement = headTags[0].innerHTML;
  }
  a.document.write("<html>");
  a.document.write(headElement);
  a.document.write("<body>");
  a.document.write("<style> body #not-print{display:none} </style>");
  a.document.write(divContents);
  a.document.write("<printelement>");
  a.document.write(divContents);
  a.document.write("</printelement></style></html>");

  a.document.close();
  a.print();
};

const printDocument = (printElementRef) => {
  let divContents = printElementRef.current.innerHTML;
  let printElement = document.getElementsByTagName("printelement");
  const HavePrintElement = printElement.length >= 1 ? true : false;

  if (!HavePrintElement) {
    console.log("createElement", HavePrintElement);
    let usingElement = document.createElement("printElement");
    usingElement.innerHTML = "";
    usingElement.innerHTML += `
    <div style="margin:30px 0 0 0;">
      <img alt="Logo" src="logo.svg" />
    </div>
    `;
    usingElement.innerHTML += divContents;
    document.body.appendChild(usingElement);
  } else {
    console.log("getElementsByName", HavePrintElement);
    const currentPrintElement =
      document.getElementsByTagName("printelement")[0];
    currentPrintElement.innerHTML = "";
    currentPrintElement.innerHTML += `
    <div style="">
      <img alt="Logo" src="logo.svg" />
    </div>
    `;
    currentPrintElement.innerHTML += divContents;
  }
  window.print();

  // console.log(usingElement);

  // document.body.removeChild(usingElement);
};

let lastId = 0;
function UniqueIdGenerator(prefix = "id") {
  lastId++;
  return `${prefix}${lastId}`;
}

const sleepCustom = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const numberToCurrency = (number) => {
  const value = parseFloat(number).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
  return value;
};

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

function vehicleOtherInfo(vehicle, type = "slash", prefix = true) {
  let text = "";

  if (vehicle.vehicleEngineNumber) {
    if (type === "bracket") {
      text += "(";
    }
    text += `${
      prefix ? "Engine # " : ""
    }${vehicle?.vehicleEngineNumber?.toUpperCase()}`;
    if (type === "bracket") {
      text += ") ";
    }
  }
  if (vehicle.vehicleChassisNumber) {
    if (type === "bracket") {
      text += "(";
    } else if (type === "slash" && vehicle.vehicleEngineNumber) {
      text += " / ";
    } else if (type === "comma" && vehicle.vehicleEngineNumber) {
      text += ", ";
    }
    text += `${
      prefix ? "Chassis # " : ""
    }${vehicle?.vehicleChassisNumber?.toUpperCase()}`;
    if (type === "bracket") {
      text += ") ";
    }
  }
  if (vehicle.vehicleLicenseNumber) {
    if (type === "bracket") {
      text += "(";
    } else if (
      type === "slash" &&
      (vehicle.vehicleEngineNumber || vehicle.vehicleChassisNumber)
    ) {
      text += " / ";
    } else if (
      type === "comma" &&
      (vehicle.vehicleEngineNumber || vehicle.vehicleChassisNumber)
    ) {
      text += ", ";
    }
    text += `${
      prefix ? "Plate # " : ""
    }${vehicle?.vehicleLicenseNumber?.toUpperCase()}`;
    if (type === "bracket") {
      text += ")";
    }
  }

  return text;
}

function formatPhoneNumber(phoneNumberString) {
  return phoneNumberString
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d{4})(\d{4})/, "+$1 $2-$3");
}

function DOMPurification(htmlContent) {
  const clean = DOMPurify.sanitize(htmlContent);
  return clean;
}

export const getSearchUrl = (searchQuery = "") => {
  return ROUTER_PATH.SEARCH + `?searchQuery=${searchQuery}`;
};

export {
  DOMPurification,
  isTwoObjectSame,
  isEmptyObject,
  getNumberOfPagesInArray,
  getNumberOfPages,
  getAppVersion,
  printDocument,
  printDocumentWindow,
  UniqueIdGenerator,
  sleepCustom,
  numberToCurrency,
  sliceIntoChunks,
  getCurrentPreviewCount,
  capitalizeFirstLetter,
  vehicleOtherInfo,
  formatPhoneNumber,
};
