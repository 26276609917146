const addSearchQueryParam = (previousState, newSearchQueryValue) => {
  let newState = {
    ...previousState,
  };
  newState.params = {
    ...newState.params,
    searchQuery: newSearchQueryValue,
  };
  return newState;
};

const addVehicleMakeParam = (previousState, newSearchQueryValue) => {
  let newState = {
    ...previousState,
  };
  newState.params = {
    ...newState.params,
    vehicleMake: newSearchQueryValue,
  };
  return newState;
};

const addVehicleModelParam = (previousState, newSearchQueryValue) => {
  let newState = {
    ...previousState,
  };
  newState.params = {
    ...newState.params,
    vehicleModel: newSearchQueryValue,
  };
  return newState;
};

const addVehicleChassisNumberParam = (previousState, newSearchQueryValue) => {
  let newState = {
    ...previousState,
  };
  newState.params = {
    ...newState.params,
    vehicleChassisNumber: newSearchQueryValue,
  };
  return newState;
};

const addVehicleEngineNumberParam = (previousState, newSearchQueryValue) => {
  let newState = {
    ...previousState,
  };
  newState.params = {
    ...newState.params,
    vehicleEngineNumber: newSearchQueryValue,
  };
  return newState;
};

const addVehicleLicenseNumberParam = (previousState, newSearchQueryValue) => {
  let newState = {
    ...previousState,
  };
  newState.params = {
    ...newState.params,
    vehicleLicenseNumber: newSearchQueryValue,
  };
  return newState;
};

const addPageIncrement = (previousState) => {
  const param = { ...previousState.params };
  param.page += 1;
  return { ...previousState, params: param };
};

export {
  addSearchQueryParam,
  addVehicleModelParam,
  addVehicleMakeParam,
  addVehicleChassisNumberParam,
  addVehicleEngineNumberParam,
  addVehicleLicenseNumberParam,
  addPageIncrement,
};
