import { legacy_createStore as createStore, applyMiddleware } from "redux";
// import logger from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import rootReducer from "./root-reducer";

let middleware = [thunk]; // all the production middleware are here
let applyAllMiddleware = applyMiddleware(...middleware);

if (process.env.NODE_ENV === "development") {
  // middleware.push(logger);
  applyAllMiddleware = applyMiddleware(...middleware);
  const enhancers = [applyAllMiddleware];
  applyAllMiddleware = composeWithDevTools(...enhancers);
}

export const store = createStore(rootReducer, applyAllMiddleware);

export const persistor = persistStore(store);
