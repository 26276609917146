const env = process.env.NODE_ENV === "production";
// const env = true;

const MIXPANEL_CONFIG = {
  key: "",
  option: {
    debug: true,
    ignore_dnt: true,
  },
};
if (env) MIXPANEL_CONFIG.key = "ec3d66890d24739a56e5a3c0ad5af131";

export default MIXPANEL_CONFIG;
