export const addItemsToOrderCart = (preState, newPayload) => {
  const current = new Date(); //'Mar 11 2015' current.getTime() = 1426060964567
  const followingDay = new Date(current.getTime() + 1 * 60000); // + 15 min in ms

  const state = {
    ...preState,
    orderId: newPayload.orderId,
    orderCart: { ...newPayload.cart },
    orderPriceSummary: { ...newPayload.priceSummary },
    sessionStart: current,
    sessionEnd: followingDay,
  };
  return {
    ...state,
  };
};

export const orderFormStateFileUpload = (preState, newPayload) => {
  const state = {
    ...preState,
  };
  state.orderFormState = { ...state.orderFormState, ...newPayload };
  return {
    ...state,
  };
};

export const addOrderAddress = (preState, newPayload) => {
  const state = {
    ...preState,
  };
  state.orderFormState.orderAddress = { ...newPayload };
  return {
    ...state,
  };
};

export const addOrderPayment = (preState, newPayload) => {
  const state = {
    ...preState,
  };
  state.orderFormState.orderPayment = { ...newPayload };
  return {
    ...state,
  };
};

export const addUserSignedInfo = (preState, newPayload) => {
  const state = {
    ...preState,
  };
  state.orderFormState.orderSignedUser = { ...newPayload };
  state.isEnd = true;
  return {
    ...state,
  };
};

export const removeFileUploadedKey = (preState) => {
  const state = {
    ...preState,
  };
  delete state.orderFormState.fileKey;
  delete state.orderFormState.fileName;
  state.isEnd = true;
  return {
    ...state,
  };
};
