import {
  addProductToCart,
  addCarIntoProductToCart,
  removeProductToCart,
  removeCarIntoProductToCart,
  addProductsFromOrderDetails,
} from "./cart.utils";

export const CART_INITIAL_STATE = {
  items: [],
  isVisible: false,
  isQuotationVisible: false,
  vehicles: [],
  productNumberCart: {},
  car_under_product: {},
  total_cart_item_count: 0,
};

const cartReducer = (state = CART_INITIAL_STATE, action) => {
  switch (action.type) {
    case "cart/ADD_PRODUCT_ITEM":
      return addProductToCart(state, action.payload);

    case "cart/ADD_CAR_INTO_PRODUCT_ITEM":
      return addCarIntoProductToCart(state, action.payload);

    case "cart/REMOVE_PRODUCT_ITEM":
      return removeProductToCart(state, action.payload);

    case "cart/REMOVE_CAR_INTO_PRODUCT_ITEM":
      return removeCarIntoProductToCart(state, action.payload);

    case "cart/ADD_PRODUCT_FROM_ORDER_DETAILS":
      return addProductsFromOrderDetails(state, action.payload);

    case "cart/CLEAR_ITEMS":
      return JSON.parse(JSON.stringify(CART_INITIAL_STATE));

    default:
      return state;
  }
};

export default cartReducer;
