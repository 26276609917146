import { addUserToState, removeUserToState } from "./user.utils";

const INITIAL_STATE = {
  user: null,
  isUserLoggedIn: false,
  isLoading: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "user/START_LOADING":
      return { ...state, isLoading: true };

    case "user/END_LOADING":
      return { ...state, isLoading: false };

    case "user/ADD_USER":
      return addUserToState(state, action.payload);

    case "user/REMOVE_USER":
      return removeUserToState(state);

    default:
      return state;
  }
};

export default userReducer;
