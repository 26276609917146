import { TRACKING_EVENT } from "data/trackingEvent.data";
import mixpanel from "mixpanel-browser";
// import { useSelector } from "react-redux";

function source() {
  if (document.referrer.search("https?://(.*)google.([^/?]*)") === 0) {
    return "Google";
  } else if (document.referrer.search("https?://(.*)bing.([^/?]*)") === 0) {
    return "Bing";
  } else if (document.referrer.search("https?://(.*)yahoo.([^/?]*)") === 0) {
    return "Yahoo";
  } else if (document.referrer.search("https?://(.*)facebook.([^/?]*)") === 0) {
    return "Facebook";
  } else if (document.referrer.search("https?://(.*)twitter.([^/?]*)") === 0) {
    return "Twitter";
  } else {
    return "Other";
  }
}
export const addMixPanelTrack =
  (eventName, eventData, options) => async (dispatch) => {
    console.log(eventName, eventData, options);
  };

function useMixpanel() {
  // const mixpanelState = useSelector((state) => state.mixpanel);

  const env = process.env.NODE_ENV === "production";
  // const env = true;

  const mixpanelIdentity = (email) => {
    if (env) {
      mixpanel.identify(email);
    }
  };

  const mixpanelSetPeople = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.people.set(eventData);
    }
  };

  const mixpanelSearchEvent = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.SEARCH_EVENT, eventData);
    }
  };

  const mixpanelProductTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.SINGLE_PRODUCT_VIEW, eventData);
    }
  };

  const mixpanelAddAutoCompleteTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.SEARCH_AUTO_COMPLETE, eventData);
    }
  };

  const mixpanelAddVehicleSelectTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_SELECT_VEHICLE, eventData);
    }
  };
  const mixpanelVehicleAddedTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_ADDED_VEHICLE, eventData);
    }
  };

  const mixpanelAddCrashApplicationTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_APP_CRASH, eventData);
    }
  };

  const mixpanelAddRouteTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(
        `${TRACKING_EVENT.mixpanel.USER_ROUTE_VISIT} ${data.pathname}`,
        eventData
      );
    }
  };

  const mixpanelAddSearchParamTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_SEARCH_PARAM, eventData);
    }
  };

  const mixpanelAddShoppingCartRawTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_SHOPPING_CART_RAW, eventData);
    }
  };

  const mixpanelAddShoppingCartItemClickTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_ADD_CART, eventData);
    }
  };

  const mixpanelAdShoppingDeductFromCartTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_DEDUCT_TO_CART, eventData);
    }
  };

  const mixpanelSearchResultEvent = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.SEARCH_RESULT_EVENT, eventData);
    }
  };

  const mixpanelAddOrderInfoTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_ORDER_PLACED, eventData);
    }
  };

  const mixpanelShopWithoutVehicleTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(
        TRACKING_EVENT.mixpanel.USER_SHOP_WITHOUT_VEHICLE,
        eventData
      );
    }
  };

  const mixpanelAbandonCartTrack = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_ABANDON_CART, eventData);
    }
  };

  const mixpanelViewCart = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_VIEW_CART, eventData);
    }
  };

  const mixpanelGenerateQuotation = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(
        TRACKING_EVENT.mixpanel.USER_GENERATE_QUOTATION,
        eventData
      );
    }
  };

  const mixpanelPrintQuotation = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_PRINT_QUOTATION, eventData);
    }
  };

  const mixpanelEditQuotation = (data = {}) => {
    const eventData = {
      sourceReference: source(),
      ...data,
    };
    if (env) {
      mixpanel.track(TRACKING_EVENT.mixpanel.USER_EDIT_QUOTATION, eventData);
    }
  };

  return {
    state: mixpanel,
    identify: mixpanelIdentity,
    setPeople: mixpanelSetPeople,
    searchEvent: mixpanelSearchEvent,
    searchResultEvent: mixpanelSearchResultEvent,
    productTrack: mixpanelProductTrack,
    editQuotation: mixpanelEditQuotation,
    printQuotation: mixpanelPrintQuotation,
    autoCompleteTrack: mixpanelAddAutoCompleteTrack,
    vehicleSelectTrack: mixpanelAddVehicleSelectTrack,
    shopWithoutVehicle: mixpanelShopWithoutVehicleTrack,
    vehicleAddedTrack: mixpanelVehicleAddedTrack,
    abandonCartTrack: mixpanelAbandonCartTrack,
    viewCart: mixpanelViewCart,
    generateQuotation: mixpanelGenerateQuotation,
    deductFromCart: mixpanelAdShoppingDeductFromCartTrack,
    crashApplicationTrack: mixpanelAddCrashApplicationTrack,
    routeTrack: mixpanelAddRouteTrack,
    searchParamTrack: mixpanelAddSearchParamTrack,
    shoppingCartRawTrack: mixpanelAddShoppingCartRawTrack,
    shoppingCartItemClickTrack: mixpanelAddShoppingCartItemClickTrack,
    userOrderPlacedTrack: mixpanelAddOrderInfoTrack,
  };
}

export default useMixpanel;
