import React from "react";
import PropTypes from "prop-types";
import style from "./index.module.scss";

function LoadingScreen({ sx }) {
  return (
    <div className={style.loading_container} style={sx}>
      <div className={style.loading_content}></div>
      <div style={{ position: "absolute" }}>
        <span className={style.text_one}>Loading</span>
      </div>
    </div>
  );
}

LoadingScreen.defaultProps = {
  sx: {},
};

LoadingScreen.propTypes = {
  sx: PropTypes.object,
};

export default LoadingScreen;
