export const getCarObjectKey = (carInfo = {}) => {
  return JSON.stringify(carInfo);
};

const addUserGarageToState = (previousState, newUserInfo) => {
  console.log("addUserGarageToState", previousState, newUserInfo);
  const getCarKey = getCarObjectKey(newUserInfo);
  const newState = { ...previousState };
  newState.garage[getCarKey] = newUserInfo;
  return newState;
};

const replaceUserGarageToState = (previousState, garageVehicles) => {
  console.log("replaceUserGarageToState", garageVehicles);
  let newState = { ...previousState, garage: {} };
  garageVehicles.forEach((vehicle) => {
    const getCarKey = getCarObjectKey(vehicle);
    newState.garage[getCarKey] = vehicle;
  });
  return newState;
};

const removeUserGarageToState = (previousState, newUserInfo) => {
  console.log("removeUserGarageToState", previousState, newUserInfo);
  return previousState;
};

export {
  addUserGarageToState,
  replaceUserGarageToState,
  removeUserGarageToState,
};
