const INITIAL_STATE = {
  screeWidth: 0,
  deviceType: "",
};

const screenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "screen/ADD_SCREEN_WIDTH":
      return { ...state, screeWidth: action.payload };

    case "screen/ADD_SCREEN_DEVICE_TYPE":
      return { ...state, deviceType: action.payload };

    default:
      return state;
  }
};

export default screenReducer;
